import React, { useState } from "react";

import {
	Editor,
	EditorState,
	convertFromRaw,
	convertToRaw,
	RichUtils
} from "draft-js";

import { markdownToDraft, draftToMarkdown } from "markdown-draft-js";

import style from "./MarkdownEditor.module.css";

export default ({ input, placeholder, className }) => {
	// Initialize the editor state to the 'value' prop, a markdown string.
	const [editorState, setEditorState] = useState(() => {
		const editorContent = convertFromRaw(markdownToDraft(input.value));
		return EditorState.createWithContent(editorContent);
	});

	const currentStyle = editorState.getCurrentInlineStyle();

	const onChange = state => {
		setEditorState(state);
		const markdownStr = draftToMarkdown(
			convertToRaw(state.getCurrentContent())
		);
		input.onChange(markdownStr);
	};

	const onUndo = () => onChange(EditorState.undo(editorState));
	const onRedo = () => onChange(EditorState.redo(editorState));

	const toggleStyle = style => {
		onChange(RichUtils.toggleInlineStyle(editorState, style));
	};
	const toggle = (e, style) => {
		e.preventDefault();
		toggleStyle(style);
	};

	const handleKeyCommand = command => {
		if (command === "bold") return toggleStyle("BOLD");
	};

	const classNames = [style.container, className].join(" ");

	return (
		<div className={classNames}>
			<div className={style.headerRow}>
				<button
					type="button"
					tabIndex="-1"
					className={currentStyle.has("BOLD") ? style.active : ""}
					onMouseDown={e => toggle(e, "BOLD")}>
					<i className="fa fa-bold" aria-hidden="true" />
				</button>

				<button type="button" tabIndex="-1" onClick={e => onUndo()}>
					<i className="fa fa-undo" aria-hidden="true" />
				</button>

				<button type="button" tabIndex="-1" onClick={e => onRedo()}>
					<i className="fa fa-repeat" aria-hidden="true" />
				</button>
			</div>

			<hr />
			<Editor
				{...input}
				editorState={editorState}
				onChange={onChange}
				placeholder={placeholder}
				handleKeyCommand={handleKeyCommand}
				spellCheck
			/>
		</div>
	);
};
