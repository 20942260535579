import { useEffect, useState } from "react";
import { useForm } from "react-final-form";

export default () => {
	const [selectedIndex, setSelectedIndex] = useState(0);
	const form = useForm();

	useEffect(() => {
		const fn = ({ active }) => {
			if (!active) return;
			if (!active.includes("products[")) return;

			// Match the first group of digits, parse as integer.
			const int = parseInt(active.match(/^(\D*)(\d+)/)[2]);

			return setSelectedIndex(int);
		};

		return form.subscribe(fn, { active: true });
	}, [form]);

	return selectedIndex;
};
