import React from "react";
import { useField } from "react-final-form";

import labels from "../data/labels.json";
import labelImages from "../data/labelImages.json";
import templates from "../data/templates";
import allergens from "../data/allergens";

import useFieldValue from "../hooks/useFieldValue";
import useFocusedRow from "../hooks/useFocusedRow";

import TextObject from "./TextObject";
import BarcodeObject from "./BarcodeObject";

import style from "./LivePreview.module.css";

const LivePreview = () => {
	const labelID = useFieldValue("labelID");
	const templateID = useFieldValue("templateID");

	const label = labels.find(label => label.id === labelID);
	const template = templates.find(template => template.id === templateID);

	const parts = label.parts.map(part => ({
		...part,
		labelImage: labelImages.find(lI => lI.id === part.labelImage)
	}));

	const src = parts[0].labelImage.url;

	const viewBox = [0, 0, parts[0].width, parts[0].height].join(",");

	const selectedIndex = useFocusedRow();

	return (
		<div className={style.container}>
			<Title selectedIndex={selectedIndex} />
			<svg viewBox={viewBox}>
				<image key={src} xlinkHref={src} preserveAspectRatio="none" />
				<Objects objects={template.objects} />
			</svg>
		</div>
	);
};

const Title = ({ selectedIndex }) => {
	const title = useFieldValue(`products[${selectedIndex}].title`);

	return (
		<h3>
			Previewing
			<br />
			<b>{title ? title : <i>Unnamed</i>}</b>
		</h3>
	);
};

const Objects = ({ objects }) => {
	const selectedIndex = useFocusedRow();

	const products = useFieldValue("products");
	const fixedText = useFieldValue("fixedText");
	const product = products[selectedIndex || 0];

	const getFieldKey = object => {
		if (object.objectType === "LINKED_TEXT") {
			return `fixedText.${object.binding}`;
		}

		if (object.objectType === "SITE_TEXT") {
			return `fixedText.${object.binding}`;
		}

		const selectedKey = `products[${selectedIndex}].${object.binding}`;

		return selectedKey;
	};

	return (
		<g>
			{objects.map(object => (
				<Offset
					key={object.id}
					fieldKey={getFieldKey(object)}
					object={object}
					product={product}>
					<TemplateObject
						product={product}
						fixedText={fixedText}
						object={object}
					/>
				</Offset>
			))}
		</g>
	);
};

const Offset = ({ object, children, fieldKey }) => {
	const field = useField(fieldKey);

	const { settings } = object;
	const originX = settings.width / 2;
	const originY = settings.height / 2;

	let transform = `translate(${settings.x} ${settings.y})`;

	if (settings.rt > 0) {
		transform += ` rotate(${settings.rt} ${originX} ${originY})`;
	}

	return (
		<g transform={transform}>
			<rect
				width={object.settings.width}
				height={object.settings.height}
				fill="none"
				stroke={field.meta.active ? "#00aac4" : "none"}
				rx={4}
			/>
			{children}
		</g>
	);
};

const TemplateObject = ({ object, product, fixedText }) => {
	let text = product[object.binding];

	if (fixedText[object.binding]) {
		text = fixedText[object.binding];
	}

	if (object.objectType === "SIMPLE_ALLERGENS") {
		const data = product[object.binding];
		if (data && data.length) {
			const names = data
				.map(id => allergens.find(a => a.id === id).name)
				.join(", ");

			text = "Allergens: " + names;
		} else {
			text = "";
		}
	}

	if (object.objectType === "BARCODE") {
		return <BarcodeObject object={object} value={text} />;
	}

	return <TextObject object={object} text={text} />;
};

export default LivePreview;
