import React from "react";

import { Button } from "./FormComponents";

import lllDirect from "../img/LLLDirect-bluelogo.svg";

import style from "./StartScreen.module.css";

const tagline = `Allow Planglow to take the effort out of label printing by using our direct overprint service.`;
const secondary = `Get compliant labelling for your Grab and Go range delivered within 1-2 weeks.`;

export default ({ start }) => (
	<div className={style.container}>
		<div>
			<h1>
				<img src={lllDirect} alt="LabelLogic Live Direct" />
			</h1>
			<h2>{tagline}</h2>
			<h3>{secondary}</h3>

			<Button className={style.button} onClick={() => start()}>
				Start now
			</Button>
		</div>
	</div>
);
