import React from "react";

import style from "./Navbar.module.css";

import directLogo from "../img/LLLDirect-bluelogo.svg";

const Header = () => (
	<>
		<div className={style.container}>
			<h1>
				<img alt="LabelLogic Live Direct" src={directLogo} />
			</h1>
			<div>
				<p>
					Planglow Ltd. • The Quorum • Bond Street South • Bristol •
					BS1 3AE
				</p>
				<p>
					<b>T</b> +44 (0) 117 317 8600
					<b>W</b> www.planglow.com
					<b>E</b> info@planglow.com
				</p>
			</div>
		</div>

		<div className={style.spacer} />
	</>
);

export default Header;
