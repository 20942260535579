import React from "react";
import { Field } from "react-final-form";

import style from "./FormComponents.module.css";

import MarkdownEditor from "./MarkdownEditor";

import allergens from "../data/allergens";

export const Condition = ({ when, is, children }) => (
	<Field name={when} subscription={{ value: true }}>
		{({ input: { value } }) => (value === is ? children : null)}
	</Field>
);

export const TextField = ({ label, meta, input }) => (
	<FieldWrapper meta={meta} label={label}>
		<input
			className={style.textInput}
			data-lpignore
			autoComplete="off"
			{...input}
		/>
	</FieldWrapper>
);

export const TextAreaField = ({ label, meta, input }) => (
	<FieldWrapper meta={meta} label={label}>
		<textarea
			className={style.textInput}
			data-lpignore
			autoComplete="off"
			{...input}
		/>
	</FieldWrapper>
);

export const RichTextField = ({ label, meta, ...rest }) => (
	<FieldWrapper meta={meta} label={label}>
		<MarkdownEditor meta={meta} className={style.textInput} {...rest} />
	</FieldWrapper>
);

export const SelectField = ({ label, meta, input, items }) => (
	<FieldWrapper meta={meta} label={label}>
		<div className={style.selectWrapper}>
			<select className={style.textInput} {...input}>
				{items.map(item => (
					<option key={item.id} value={item.id}>
						{item.name}
					</option>
				))}
			</select>
		</div>
	</FieldWrapper>
);

const FieldWrapper = ({ meta: { active }, label, children }) => (
	<div className={`${style.fieldWrapper} ${active ? style.focused : ""}`}>
		<label>{label}</label>
		{children}
	</div>
);

export const InlineFields = ({ className, children }) => {
	const classNames = [style.inlineFields, className];

	return <div className={classNames.join(" ")}>{children}</div>;
};

export const Button = ({ small, centre, className, ...rest }) => {
	const classNames = [
		className,
		style.button,
		small ? style.btnSmall : "",
		centre ? style.centre : ""
	];

	return <button className={classNames.join(" ")} type="button" {...rest} />;
};

export const Panel = ({ children, noMargin }) => {
	const classNames = [style.Panel, noMargin ? "" : style.panelMargin];

	return <div className={classNames.join(" ")} children={children} />;
};

const allergenURL = id => {
	return `https://allergen-images.s3.amazonaws.com/planglowltd/${id}.svg`;
};

export const CheckboxAllergens = ({ fieldName }) => (
	<div className={style.allergens}>
		{allergens.map(allergen => (
			<label key={allergen.id}>
				<Field
					name={fieldName}
					type="checkbox"
					value={allergen.id}
					component="input"
				/>
				<img src={allergenURL(allergen.id)} />
			</label>
		))}
	</div>
);
