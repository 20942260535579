import qs from "qs";

import labels from "../data/labels.json";

export default () => {
	const params = qs.parse(window.location.search.substring(1));

	const label = labels.find(label => label.sheet.sheetCode === params.label);

	return label?.id;
};
