import React, { useEffect, useRef } from "react";
import { Field, useForm } from "react-final-form";

import { SelectField, InlineFields } from "./FormComponents";

import labels from "../data/labels.json";
import templates from "../data/templates.json";
import useFieldValue from "../hooks/useFieldValue.js";

const normalisedLabels = labels.map(label => ({
	...label,
	templates: templates.filter(template => template.label === label.id)
}));

const filteredLabels = normalisedLabels
	.filter(label => label.parts.length === 1)
	.filter(label => label.templates.length > 0)
	.sort((a, b) => {
		if (a.name > b.name) return 1;
		if (b.name > a.name) return -1;
		return 0;
	});

export default () => (
	<InlineFields>
		<ChooseLabel />
		<ChooseTemplate />
	</InlineFields>
);

const ChooseLabel = () => (
	<Field
		label="Label"
		component={SelectField}
		name="labelID"
		initialValue={filteredLabels[0].id}
		items={filteredLabels.map(label => ({
			name: label.name,
			id: label.id
		}))}
	/>
);

const ChooseTemplate = () => {
	const labelID = useFieldValue("labelID");

	const form = useForm();

	const filteredTemplates = templates.filter(
		template => template.label === labelID
	);

	const last = useRef(labelID);

	useEffect(() => {
		if (last.current === labelID) return;
		last.current = labelID;
		const first = templates.find(template => template.label === labelID);
		if (!first) return;
		form.change("templateID", first.id);
	}, [labelID, form]);

	return (
		<Field
			label="Template"
			component={SelectField}
			name="templateID"
			initialValue={filteredTemplates[0].id}
			items={filteredTemplates.map(template => ({
				name: template.name,
				id: template.id
			}))}
		/>
	);
};
