import React from "react";

import { FieldArray } from "react-final-form-arrays";

import LabelOptions from "./LabelOptions";

import Products from "./Products";
import FixedText from "./FixedText";
import DeliveryDetails from "./DeliveryDetails";
import Confirmation from "./Confirmation";
import { Panel } from "./FormComponents";

import style from "./DirectForm.module.css";
import LLLPlug from "./LLLPlug";

const DirectForm = ({ handleSubmit }) => (
	<form onSubmit={handleSubmit} className={style.container}>
		<Panel>
			<h3>{"Label & Template"}</h3>
			<p>
				Select a label from the list, then select a layout using the{" "}
				<i>Template</i> option.
			</p>
			<hr />
			<LabelOptions />
		</Panel>

		<Panel>
			<h3>Products</h3>
			<p>
				Add a row for each unique product you require a label for.
				Labels can be duplicated by using the <i>Quantity</i> column.
			</p>
			<hr />
			<FieldArray name="products" component={Products} />
		</Panel>

		<Panel>
			<h3>Fixed Text Fields</h3>
			<p>Fixed text fields are applied to all products.</p>
			<hr />
			<FixedText />
		</Panel>

		<Panel>
			<h3>Delivery Details</h3>

			<hr />
			<DeliveryDetails />
		</Panel>

		<LLLPlug />

		<Panel>
			<Confirmation />
		</Panel>
	</form>
);

export default DirectForm;
