import React, { Fragment } from "react";
import { Field } from "react-final-form";

import templates from "../data/templates.json";
import fixedTexts from "../data/fixedTexts";

import useFieldValue from "../hooks/useFieldValue.js";
import { RichTextField } from "./FormComponents";

const bindingNames = {
	companyDetails: "Company Details",
	companyName: "Company Name"
};

const getObjectName = object => {
	if (bindingNames[object.binding]) return bindingNames[object.binding];

	if (object.objectType === "LINKED_TEXT") {
		const linkedText = fixedTexts.find(txt => txt.id === object.binding);
		return linkedText.name;
	}
};

export default () => {
	const templateID = useFieldValue("templateID");

	const template = templates.find(t => t.id === templateID);

	const knownObjects = template.objects.filter(object => {
		if (Object.keys(bindingNames).includes(object.binding)) return true;
		if (object.objectType === "LINKED_TEXT") return true;

		return false;
	});

	if (!knownObjects.length) return null;

	return (
		<>
			{knownObjects.map((object, index) => (
				<Fragment key={object.id}>
					<Field
						label={"Text Area " + (index + 1)}
						component={RichTextField}
						name={"fixedText." + object.binding}
						placeholder={getObjectName(object)}
					/>
				</Fragment>
			))}
		</>
	);
};
