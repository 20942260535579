import React, { useEffect, useRef } from "react";

const htmlFromMarkdown = text => {
	text = text.replace(/\r?\n/g, "<br>");

	const splitTextArray = text.split(/\*\*(.*?)\*\*/);

	let outputHTML = "";
	let currentlyBold = false;
	splitTextArray.forEach(textSection => {
		if (currentlyBold) {
			outputHTML += `<strong>${textSection}</strong>`;
		} else {
			outputHTML += textSection;
		}
		currentlyBold = !currentlyBold;
	});

	return outputHTML;
};

const TextObject = ({ object, text }) => {
	const textEl = useRef();

	// Re-calculate fontSize after a field change.
	useEffect(() => {
		let fontSize = object.settings.fontSize;

		const foreignObjectEl = textEl.current.parentNode;
		const boxDimensions = foreignObjectEl.getBoundingClientRect();

		textEl.current.style.fontSize = fontSize + "px";
		textEl.current.style.position = "absolute";
		let textDimensions = textEl.current.getBoundingClientRect();

		let iterations = 0;
		while (
			textDimensions.height > boxDimensions.height ||
			textDimensions.width > boxDimensions.width
		) {
			fontSize -= 0.25;

			textEl.current.style.fontSize = fontSize + "px";
			textDimensions = textEl.current.getBoundingClientRect();

			iterations++;
			if (iterations > 1000) break;
			if (fontSize === 6) break;
		}

		textEl.current.style.position = "relative";
	}, [text, object.settings]);

	let style = {
		margin: 0
	};

	if (object.settings.hAlign === "middle") {
		style.textAlign = "center";
	}

	if (object.settings.vAlign === "middle") {
		style.top = "50%";
		style.transform = "translateY(-50%)";
	}
	if (object.settings.vAlign === "bottom") {
		style.top = "100%";
		style.transform = "translateY(-100%)";
	}

	const html = htmlFromMarkdown(text || "");

	return (
		<foreignObject
			width={object.settings.width}
			height={object.settings.height}>
			<p
				ref={textEl}
				style={style}
				dangerouslySetInnerHTML={{ __html: html }}
			/>
		</foreignObject>
	);
};

export default TextObject;
