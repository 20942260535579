import bwipjs from "bwip-js";
import DrawingSVG from "bwip-js/examples/drawing-svg.js";

export default params => {
	const svgTest = bwipjs.render(params, DrawingSVG(params, bwipjs.FontLib));

	const parser = new DOMParser();
	const xml = parser.parseFromString(svgTest, "image/svg+xml");

	// Query the Document object for svg root node, retrieve attributes.
	let width = xml.querySelector("svg").getAttribute("width");
	let height = xml.querySelector("svg").getAttribute("height");

	// Remove px from string, parse into Float
	width = parseFloat(width.replace("px", ""));
	height = parseFloat(height.replace("px", ""));

	return {
		width: Math.round((width * 25.4) / 72),
		height: Math.round((height * 25.4) / 72)
	};
};
