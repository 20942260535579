import React, { useState } from "react";
import Modal from "react-modal";
import { Field } from "react-final-form";

import { modal } from "../context";

import {
	RichTextField,
	Button,
	Panel,
	CheckboxAllergens
} from "./FormComponents";

import style from "./Modal.module.css";

export default ({ children }) => {
	const [isOpen, setOpen] = useState(false);
	const [options, setOptions] = useState({});

	const hideModal = () => setOpen(false);

	const context = {
		open: (type, options) => {
			setOpen(true);
			setOptions({ type, ...options });
		}
	};

	return (
		<modal.Provider value={context}>
			<Modal
				className={style.container}
				overlayClassName={style.overlay}
				isOpen={isOpen}
				onRequestClose={hideModal}>
				<Panel noMargin>
					{options.type === "MARKDOWN" && (
						<Ingredients {...options} />
					)}

					{options.type === "SIMPLE_ALLERGENS" && (
						<SimpleAllergens {...options} />
					)}

					<Button onClick={hideModal} centre>
						Done
					</Button>
				</Panel>
			</Modal>
			{children}
		</modal.Provider>
	);
};

const Ingredients = ({ fieldName, productTitle }) => (
	<>
		<h3>Ingredients</h3>
		<p>
			Editing ingredients listing for <b>{productTitle}</b>
		</p>

		<hr />
		<Field name={fieldName} component={RichTextField} />
	</>
);

const SimpleAllergens = ({ fieldName, productTitle }) => (
	<>
		<h3>Allergens</h3>
		<p>
			Editing allergen listing for <b>{productTitle}</b>
		</p>

		<hr />
		<CheckboxAllergens fieldName={fieldName} />
	</>
);
