import React, { useEffect, useState } from "react";
import bwipjs from "bwip-js";

import getBarcodeDimensions from "../utils/getBarcodeDimensions";

import DrawingSVG from "bwip-js/examples/drawing-svg.js";

// LLL field bindings to bwip-js 'bcid' values.
const bcidMappings = {
	barcodeEAN8: "ean8",
	barcodeEAN13: "ean13",
	barcodeCode128: "code128",
	barcodeGS1128: "gs1-128",
	barcodeUPCA: "upca",
	barcodeQRCode: "qrcode",
	barcodeITF14: "itf14"
};

// Points in 1 millimeter.
const PTS_TO_MM = 2.83465;

export default ({ object, value }) => {
	const [svgData, setSvgData] = useState();
	const { settings } = object;

	const params = {
		// Barcode type
		bcid: bcidMappings[object.binding],

		text: value,

		scale: 1,
		height: 10,

		// Show human-readable text
		includetext: true,
		guardwhitespace: true
	};

	bwipjs.fixupOptions(params);

	useEffect(() => {
		if (!value) return setSvgData();
		try {
			const dimensions = getBarcodeDimensions(params);

			const boxHeight = settings.height / PTS_TO_MM;
			const boxWidth = settings.width / PTS_TO_MM;

			const textAreaHeight = dimensions.height - 10;

			const widthScaling = boxWidth / dimensions.width;

			const scaledHeight = boxHeight / widthScaling - textAreaHeight;

			const finalParams = {
				...params,
				height: scaledHeight,
				scale: widthScaling
			};

			bwipjs.fixupOptions(finalParams);

			const svgCode = bwipjs.render(
				finalParams,
				DrawingSVG(finalParams, bwipjs.FontLib)
			);

			setSvgData(svgCode);
		} catch (e) {
			setSvgData("invalid");
		}
	}, [params, value, settings.height, settings.width]);

	if (!value) return null;

	if (svgData === "invalid") {
		return (
			<text
				y={settings.height / 2}
				x={settings.width / 2}
				dominantBaseline="middle"
				textAnchor="middle"
				fill="firebrick">
				<tspan>Invalid Barcode</tspan>
			</text>
		);
	}

	return <g dangerouslySetInnerHTML={{ __html: svgData }} />;
};
