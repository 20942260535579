import React from "react";

import { Field } from "react-final-form";
import {
	Condition,
	InlineFields,
	TextField,
	TextAreaField
} from "./FormComponents";

export default () => (
	<InlineFields>
		<div>
			<h4>Delivery Address</h4>
			<p>Deliveries cannot be made to a P.O Box.</p>

			<Field
				label="Planglow Account Number (if known)"
				component={TextField}
				name="delivery.accountNumber"
			/>

			<InlineFields>
				<Field
					label="Company Name"
					component={TextField}
					name="delivery.companyName"
				/>
				<Field
					label="Contact Name"
					component={TextField}
					name="delivery.contactName"
				/>
			</InlineFields>
			<Field
				label="Address"
				component={TextAreaField}
				name="delivery.address"
			/>
			<InlineFields>
				<Field
					label="Post Code"
					component={TextField}
					name="delivery.postCode"
				/>
				<Field
					label="Phone Number"
					component={TextField}
					name="delivery.phoneNumber"
				/>
			</InlineFields>
		</div>
		<div>
			<h4>Invoice address</h4>
			<label>
				<Field
					component="input"
					name="invoice.useDelivery"
					type="checkbox"
					initialValue={true}
				/>
				Use delivery address
			</label>

			<Condition when="invoice.useDelivery" is={false}>
				<Field
					label="Company Name"
					component={TextField}
					name="delivery.companyName"
				/>
				<Field
					label="Address"
					component={TextAreaField}
					name="delivery.address"
				/>
				<Field
					label="Post Code"
					component={TextField}
					name="delivery.postCode"
				/>
			</Condition>
		</div>
	</InlineFields>
);
