import React from "react";
import { Field } from "react-final-form";

import useFieldValue from "../hooks/useFieldValue";

import { Button } from "./FormComponents";

export default () => {
	const products = useFieldValue("products");

	const total = products.reduce((total, product) => {
		return total + product.quantity;
	}, 0);

	return (
		<>
			<label>
				<Field component="input" name="confirm" type="checkbox" />
				<b>
					I understand that Planglow accepts no responsibility for
					customer errors with regards to this order.
				</b>
			</label>
			<p>{"Total: " + total}</p>
			<p>{"Minimum order: " + Math.ceil(total / 500) * 500}</p>
			<Button type="submit" centre>
				Confirm
			</Button>
		</>
	);
};
