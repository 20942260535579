import React from "react";

import LLLlogo from "../img/LLL_Logo.png";

import style from "./LLLPlug.module.css";

import { Button } from "./FormComponents";

export default () => (
	<div className={style.container}>
		<img alt="LabelLogic Live logo" src={LLLlogo} />
		<div className={style.text}>
			<p>
				Need RDI fields, recipe management, complete template
				customization and and much more?
			</p>
			<p>
				Check out <b>LabelLogic Live</b> starting from £10/month.
			</p>
		</div>

		<a
			href="https://labellogiclive.com"
			target="_blank"
			rel="noopener noreferrer">
			<Button type="button">Find out more</Button>
		</a>
	</div>
);
