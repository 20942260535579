import React from "react";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";

import labelIDFromQueryString from "../utils/labelIDFromQueryString";

import Navbar from "./Navbar";
import LivePreview from "./LivePreview";
import DirectForm from "./DirectForm";
import Modal from "./Modal";

import style from "./App.module.css";

const initialValues = {
	labelID: labelIDFromQueryString() || "5b4a08a6d849770598afe337",

	products: [
		{
			title: "Cheese & Tomato",
			description: "Mature Cheddar Cheese and Tomato on Wholemeal Bread",
			price: "£1.99",
			quantity: 125,
			barcodeEAN13: "123456789012",
			barcodeEAN8: "",
			barcodeQRCode: "https://labellogiclive.com",
			ingredients:
				"Extra Mature Cheddar Cheese (**Milk**) (29%), Wheat Flour (**Wheat** Flour, Calcium Carbonate, Iron, Niacin, Thiamin), Water, Tomato (13%), Rapeseed Oil, Pasteurised **Egg**, Invert Sugar Syrup, Pasteurised **Egg** Yolk, Salt, Spirit Vinegar, Yeast, **Wheat** Gluten, Cracked Black Pepper, Sugar, Emulsifiers (Mono- and Di-Glycerides of Fatty Acids, Mono- and Di-Acetyl Tartaric Acid Esters of Mono- and Di-Glycerides of Fatty Acids, Sodium Salts of Fatty Acids), Palm Oil, Cornflour, **Mustard** Flour, Concentrated Lemon Juice, Flour Treatment Agent (Ascorbic Acid), **Wheat** Starch."
		},
		{ quantity: 0 },
		{ quantity: 0 }
	]
};

export default () => (
	<Form
		onSubmit={data => alert("success! " + JSON.stringify(data))}
		subscription={{}}
		initialValues={initialValues}
		mutators={arrayMutators}
		render={({ handleSubmit }) => (
			<Modal>
				<Navbar />
				<div className={style.container}>
					<DirectForm handleSubmit={handleSubmit} />
					<LivePreview />
				</div>
			</Modal>
		)}
	/>
);
