import React, { useState } from "react";
import ReactDOM from "react-dom";
import ReactModal from "react-modal";

import App from "./components/App";
import StartScreen from "./components/StartScreen";

ReactModal.setAppElement("body");

const Main = () => {
	const [hasBegun, setBugun] = useState(false);

	if (hasBegun) {
		return <App />;
	}

	return <StartScreen start={() => setBugun(true)} />;
};

ReactDOM.render(<Main />, document.getElementById("root"));
