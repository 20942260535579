import React from "react";
import { Field } from "react-final-form";

import useFieldValue from "../hooks/useFieldValue";
import useFocusedRow from "../hooks/useFocusedRow";

import { Button } from "./FormComponents";

import useModal from "../hooks/useModal";

import templates from "../data/templates.json";

import style from "./Products.module.css";

const fieldColumns = {
	title: {
		name: "Title",
		placeholder: "Product Title"
	},
	description: {
		name: "Description",
		placeholder: "Product description"
	},
	price: {
		name: "Price",
		placeholder: "price",
		type: "currency"
	},
	quantity: {
		name: "Quantity",
		type: "number"
	},
	ingredients: {
		name: "Ingredients",
		type: "MARKDOWN"
	},
	allergens: {
		name: "Allergens",
		type: "SIMPLE_ALLERGENS"
	},
	barcodeEAN13: { name: "EAN-13 Barcode", type: "barcode" },
	barcodeEAN8: { name: "EAN-8 Barcode", type: "barcode" },
	barcodeQRCode: { name: "QR-code", type: "barcode" }
};

export default ({ fields }) => {
	const templateID = useFieldValue("templateID");
	const template = templates.find(template => template.id === templateID);

	const allowedBindings = Object.keys(fieldColumns);

	const allowedObjects = template.objects
		.filter(object => {
			return allowedBindings.includes(object.binding);
		})
		.map(object => ({
			...object,
			...fieldColumns[object.binding]
		}));

	const quantityField = {
		...fieldColumns.quantity,
		binding: "quantity"
	};

	const columns = [...allowedObjects, quantityField];

	return (
		<>
			<table className={style.table}>
				<thead>
					<tr>
						<th>Index</th>
						<FieldHeadings columns={columns} />
					</tr>
				</thead>
				<tbody>
					{fields.map((field, index) => (
						<ProductRow
							key={index}
							index={index}
							name={field}
							columns={columns}
						/>
					))}
					<Totals columnCount={columns.length} />
				</tbody>
			</table>
			<Button onClick={() => fields.push({})} centre>
				Add Product
			</Button>
		</>
	);
};

const FieldHeadings = ({ columns }) => (
	<>
		{columns.map(value => (
			<th key={value.binding}>{value.name}</th>
		))}
	</>
);

const ProductRow = ({ index, name, columns }) => {
	const selectedIndex = useFocusedRow();

	const rowStyle = {
		background: index === selectedIndex ? "#eee" : ""
	};

	return (
		<tr style={rowStyle}>
			<td className={style.cell + " " + style.smallCell}>{index + 1}</td>

			{columns.map(column => (
				<ProductCell
					key={column.binding}
					column={column}
					fieldName={name + "." + column.binding}
					titleField={name + ".title"}
				/>
			))}
		</tr>
	);
};

const typeDefaults = {
	number: {
		parse: Number,
		initialValue: 0
	},
	currency: {
		initialValue: "£"
	}
};

const ProductCell = ({ column, fieldName, titleField }) => {
	const value = useFieldValue(fieldName);
	const productTitle = useFieldValue(titleField);

	const modal = useModal();

	let fieldDefaults = typeDefaults[column.type];

	const handleOpenModal = type => {
		modal.open(type, { fieldName, productTitle });
	};

	if (column.type === "MARKDOWN") {
		return (
			<td className={style.cell}>
				<Button
					type="button"
					onClick={() => handleOpenModal("MARKDOWN")}
					small>
					{value.length ? "Edit" : "Add"}
				</Button>
			</td>
		);
	}

	if (column.type === "SIMPLE_ALLERGENS") {
		return (
			<td className={style.cell}>
				<Button
					type="button"
					onClick={() => handleOpenModal("SIMPLE_ALLERGENS")}
					small>
					{value.length ? "Edit" : "Add"}
				</Button>
			</td>
		);
	}

	const smallFields = ["price", "quantity"];

	let classNames = [
		style.cell,
		smallFields.includes(column.binding) ? style.smallCell : ""
	];

	return (
		<td className={classNames.join(" ")}>
			<Field
				{...fieldDefaults}
				component="input"
				type={column.type}
				name={fieldName}
				data-lpignore
				autoComplete="off"
			/>
		</td>
	);
};

const Totals = ({ columnCount }) => {
	const products = useFieldValue("products");
	const totalQuantity = products.reduce(
		(acc, product) => acc + product.quantity,
		0
	);
	const totalBoxes = Math.ceil(totalQuantity / 500);

	return (
		<>
			<tr className={style.quantityRow}>
				<td colSpan={columnCount}>Total Labels:</td>
				<td>{totalQuantity}</td>
			</tr>
			<tr className={style.quantityRow}>
				<td colSpan={columnCount}>Total Boxes:</td>
				<td>{totalBoxes}</td>
			</tr>
		</>
	);
};
